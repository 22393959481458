<template>
    <div style="background-color: white;display: flex;flex-direction: column;">
        <div style="display: flex;flex-direction: row;padding: 25px;">
            <div class="condition" style="white-space: nowrap">
                <a-input
                        v-model="qTitle"
                        style="width: 180px; margin-right: 15px"
                        placeholder="输入客户"
                ></a-input>
            </div>
            <div class="condition" style="white-space: nowrap">
                <a-button style="margin-right: 20px">重置</a-button>
                <a-button type="primary"  style="margin-right: 20px">查询</a-button>
                <a-button type="primary"  style="margin-right: 20px;background-color: rgb(251 82 82);border-color: rgb(251 82 82)">高级查询</a-button>
                <a-button type="primary"  style="margin-right: 20px;background-color: #56AF3D;border-color: #56AF3D" @click="add">新建客户</a-button>

            </div>
        </div>
        <div style="display: flex;">
            <div style="overflow-y:scroll;max-height: 90vh;overflow-x:hidden">
                <div v-for="(item,index) in 10" style="width: 380px;margin-bottom: 1px;color:rgb(157 157 157);font-size: 13px;display: flex;border-bottom: 1px solid #d7d7d7;padding: 25px;">
                    <div><a-icon type="environment" theme="filled" style="    color: #f51e1e;
    font-size: 19px;"/></div>
                    <div style="width: 250px;margin-left: 10px;">
                        <div>刘洋</div>
                        <div>负责人:李想</div>
                        <div>客户地址：广东省深圳市南山大道1号</div>
                    </div>
<!--                    <a-divider></a-divider>-->
                </div>

            </div>

<!--            <baidu-map class="map" center="北京" :scroll-wheel-zoom="true">-->
            <baidu-map class="map" :scroll-wheel-zoom="true" :center="{lng: 116.404, lat: 39.915}" :zoom="15">
                <bm-marker :position="{lng: item.lng, lat: item.lat}" @click="infoWindowOpen"   :icon="{url: 'https://smart-resource.sikegroup.com/saas/image/mapIcon.png', size: {width: 65, height: 33}}"  v-for="(item,index) in customerList">
                    <bm-info-window :show="item.lng===current_lng&&item.lat===current_lat?true:false"  @close="infoWindowClose" @open="infoWindowOpen">
                        <div>李想</div>
                        <div>广东省深圳市南山区大道90324号</div>
                        <div>客户状态：成交</div>
                        <div>顾问：王丽</div>
                        <div>创建时间：2020-10-10</div>
                        <div>最后跟踪时间：2022-11-10</div>
                    </bm-info-window>
                </bm-marker>
<!--                中心图标-->
<!--                <bm-marker :position="{lng: 116.414, lat: 39.905}" animation="BMAP_ANIMATION_BOUNCE" :icon="{url: 'https://smart-resource.sikegroup.com/saas/image/mapIcon.png', size: {width: 36, height: 36}}"></bm-marker>-->
<!--                添加定位控件-->
                <bm-geolocation anchor="BMAP_ANCHOR_BOTTOM_RIGHT" :showAddressBar="true" :autoLocation="true"></bm-geolocation>
            </baidu-map>
        </div>

    </div>

</template>
<script>
    export default {

        data() {
            return {
                qTitle:'',
                customerList:[
                    {address:'北京天安门',lng:116.403,lat:39.913},
                    {address:'八达岭',lng:116.504,lat:39.916},
                    {address:'长城',lng:116.405,lat:39.915},
                    {address:'世纪大厦',lng:116.444,lat:39.917},
                ],
                current_lng:'116.404',
                current_lat:'39.915',
            };
        },

        methods: {
            add(){
                this.$router.push({ path: '/addCustomer', query: { Id: 8 }})
            },
            infoWindowClose () {
            },
            infoWindowOpen (e) {
                console.log(e)
                let that  = this;
                this.current_lng = e.currentTarget.Nv.lng
                this.current_lat = e.currentTarget.Nv.lat
                console.log(this.current_lat)
                console.log(this.current_lng)
            },
        },
    }
</script>
<style>
    .map {
        width: 100%;
        min-height: 90vh;
    }
</style>